<template>
  <div>
    <nav class="bg-gray-800 text-white p-4">
      <router-link to="/login" v-if="!loggedIn" class="ml-5mr-4">Einloggen</router-link>
      <router-link to="/settings" v-if="loggedIn" class="mr-4">Einstellungen</router-link>
      <router-link to="/assignment" v-if="loggedIn" class="mr-4">Wichteln</router-link>
      <router-link to="/admin" v-if="loggedIn && isAdmin" class="mr-4">Admin</router-link>
      <button v-if="loggedIn" @click="logout">Ausloggen</button>
    </nav>
    <router-view></router-view>
  </div>
</template>

<script>
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';

  export default {
    setup() {
      const store = useStore();
      const router = useRouter();

      const loggedIn = computed(() => store.state.loggedIn);
      const isAdmin = computed(() => store.state.userRole === 'admin');

      const logout = () => {
        store.dispatch('logout');
        router.push('/login');
      };

      return { loggedIn, isAdmin, logout };
    },
  };
</script>
