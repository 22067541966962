import {createStore} from 'vuex';
import api from "@/services/api";

export default createStore({
    state: {
        token: localStorage.getItem('token') || '',
        loggedIn: !!localStorage.getItem('token'),
        userRole: localStorage.getItem('userRole') || '',  // Load userRole from localStorage
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
            state.loggedIn = true;
            localStorage.setItem('token', token);
        },
        setUserRole(state, role) {
            state.userRole = role;
            localStorage.setItem('userRole', role);  // Persist userRole in localStorage
        },
        clearToken(state) {
            state.token = '';
            state.loggedIn = false;
            state.userRole = '';
            localStorage.removeItem('token');
            localStorage.removeItem('userRole');
        },
    },
    actions: {
        async login({commit}, credentials) {
            try {
                const response = await api.post('/api/auth/login', credentials);
                commit('setToken', response.data.token);
                commit('setUserRole', response.data.role);
                return response.data;
            } catch (error) {
                throw error.response.data;  // This allows us to handle errors on the frontend
            }
        },
        logout({commit}) {
            commit('clearToken');
        },
    },
});
