<template>
  <div class="max-w-4xl mx-auto my-10 p-6 bg-white rounded-lg shadow-lg">
    <h2 class="text-3xl font-semibold text-gray-800 mb-6 text-center">Admin Dashboard</h2>

    <!-- Button Section -->
    <div class="flex justify-between mb-6">
      <button
        @click="assignUsers"
        class="bg-blue-600 text-white px-6 py-2 rounded-lg font-medium hover:bg-blue-700 transition duration-200"
      >
        Wichtelpersonen zuweisen
      </button>
      <button
        @click="resetAssignments"
        class="bg-red-600 text-white px-6 py-2 rounded-lg font-medium hover:bg-red-700 transition duration-200"
      >
        Alle Wichtelpersonen zurücksetzen
      </button>
      <button
        @click="openAddUserModal"
        class="bg-green-500 text-white px-6 py-2 rounded-lg font-medium hover:bg-green-600 transition duration-200"
      >
        Wichtler hinzufügen
      </button>
    </div>

    <!-- Users List Section -->
    <div v-if="users.length" class="space-y-4">
      <div
        v-for="user in users"
        :key="user.username"
        class="p-4 border rounded-lg bg-gray-50 shadow-sm cursor-pointer"
        @click="toggleUser(user.username)"
      >
        <div class="flex justify-between items-center">
          <!-- Left-aligned heading -->
          <h3 class="text-lg font-semibold text-gray-700 flex-grow">
            {{ user.details?.firstName || '(Kein Vorname)' }} {{ user.details?.lastName || '(Kein Nachname)' }}
            <span class="text-sm text-gray-500">(Username: {{ user.username }}, Rolle: {{ user.role?.name || 'Keine Rolle!' }})</span>
          </h3>

          <!-- Right-aligned buttons container -->
          <div class="flex space-x-2">
            <button
                @click.stop="toggleAdmin(user.id)"
                :class="user.role.name === 'admin' ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'"
                class="text-white px-3 py-1 rounded-lg transition duration-200"
            >
              {{ user.role.name === 'admin' ? 'Admin' : 'User' }}
            </button>
            <button
                @click.stop="toggleBlock(user.id)"
                :class="user.blocked ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'"
                class="text-white px-3 py-1 rounded-lg transition duration-200"
            >
              {{ user.blocked ? 'Unblock' : 'Block' }}
            </button>
          </div>
      </div>
        <div v-if="toggledUsers.includes(user.username)" class="mt-3">
          <p class="text-sm text-gray-600">Wichtelperson: {{ user.assignments[0]?.assignedUser?.username || '(Keine Daten)' }}</p>
          <p class="text-sm text-gray-600">Vorname: {{ user.details?.firstName || '(Keine Daten)' }}</p>
          <p class="text-sm text-gray-600">Nachname: {{ user.details?.lastName || '(Keine Daten)' }}</p>
          <p class="text-sm text-gray-600">Adrese: {{ user.details?.address || '(Keine Daten)' }}</p>
          <p class="text-sm text-gray-600">Über mich: {{ user.details?.about || '(Keine Daten)' }}</p>
        </div>
    </div>

    <!-- Add User Modal -->
    <div v-if="showAddUserModal" class="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
      <div class="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
        <h3 class="text-lg font-semibold mb-4">Neuen Wichtler hinzufügen</h3>
        <form @submit.prevent="addUser">
          <input
            v-model="newUser.username"
            type="text"
            placeholder="Username"
            class="w-full mb-3 p-2 border rounded-lg"
            required
          />
          <input
            v-model="newUser.password"
            type="password"
            placeholder="Password"
            class="w-full mb-3 p-2 border rounded-lg"
            required
          />
          <button
            type="submit"
            class="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition duration-200"
          >
            Wichtler hinzufügen
          </button>
          <button
            @click="closeAddUserModal"
            type="button"
            class="w-full bg-gray-500 text-white py-2 mt-2 rounded-lg hover:bg-gray-600 transition duration-200"
          >
            Abbrechen
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { ref, onMounted } from 'vue';
import api from "@/services/api";

export default {
  setup() {
    const users = ref([]);
    const showAddUserModal = ref(false);
    const newUser = ref({ username: '', password: '' });
    const toggledUsers = ref([]);

    const fetchUsers = async () => {
      try {
        const response = await api.get('/api/admin/all-users', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        users.value = response.data;
      } catch (error) {
        console.error('Failed to load users:', error.response?.data?.error || error.message);
      }
    };

    const assignUsers = async () => {
      try {
        await api.post('/api/admin/assign-users', {}, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        fetchUsers(); // Refresh users after assignment
        alert('Users assigned successfully');
      } catch (error) {
        console.error('Assignment failed:', error.response?.data?.error || error.message);
      }
    };

    const resetAssignments = async () => {
      try {
        await api.post('/api/admin/reset-assignments', {}, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        fetchUsers(); // Refresh users after resetting assignments
        alert('All user assignments have been reset');
      } catch (error) {
        console.error('Failed to reset assignments:', error.response?.data?.error || error.message);
      }
    };

    const toggleBlock = async (username) => {
      try {
        await api.post(`/api/admin/toggle-block/${username}`, {}, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        fetchUsers(); // Refresh users after toggling block status
      } catch (error) {
        console.error('Failed to toggle block:', error.response?.data?.error || error.message);
      }
    };

    const addUser = async () => {
      try {
        await api.post('/api/admin/add-user', newUser.value, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        fetchUsers(); // Refresh users after adding new user
        newUser.value = { username: '', password: '' }; // Clear form
        closeAddUserModal();
        alert('User added successfully');
      } catch (error) {
        console.error('Failed to add user:', error.response?.data?.error || error.message);
      }
    };

    const toggleAdmin = async (userId) => {
      try {
        const response = await api.post(`/api/admin/toggle-admin/${userId}`, {}, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });

        // Update user's role in local state based on response
        const updatedRoleId = response.data.roleId;
        const updatedRoleName = updatedRoleId === 1 ? 'admin' : 'user';

        // Update the specific user in the users array
        const userIndex = users.value.findIndex(user => user.id === userId);
        if (userIndex !== -1) {
          users.value[userIndex].roleId = updatedRoleId;
          users.value[userIndex].role.name = updatedRoleName;
        }

        console.log(response.data.message);
      } catch (error) {
        console.error('Error toggling admin role:', error.response?.data?.error || error.message);
      }
    };

    const openAddUserModal = () => {
      showAddUserModal.value = true;
    };

    const closeAddUserModal = () => {
      showAddUserModal.value = false;
    };

    const toggleUser = (username) => {
      if (toggledUsers.value.includes(username)) {
        toggledUsers.value = toggledUsers.value.filter((user) => user !== username);
      } else {
        toggledUsers.value.push(username);
      }
    };

    onMounted(fetchUsers);

    return {
      users,
      showAddUserModal,
      newUser,
      toggledUsers,
      assignUsers,
      resetAssignments,
      toggleBlock,
      addUser,
      openAddUserModal,
      closeAddUserModal,
      toggleAdmin,
      toggleUser
    };
  },
};
</script>
