<template>
  <div class="container mx-auto py-8">
    <AdminDashboard />
  </div>
</template>

<script>
  import AdminDashboard from '../components/AdminDashboard.vue';

  export default {
    name: 'AdminView',
    components: { AdminDashboard },
  };
</script>
