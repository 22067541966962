import { createRouter, createWebHistory } from 'vue-router';
import Login from './views/Login.vue';
import UserSettings from './views/UserSettings.vue';
import Assignment from './views/Assignment.vue';
import Admin from './views/Admin.vue';
import store from './store';

const routes = [
    { path: '/login', component: Login },
    { path: '/settings', component: UserSettings, meta: { requiresAuth: true, title: 'Einstellungen | Wichteln'} },
    { path: '/assignment', component: Assignment, meta: { requiresAuth: true, title: 'Wichtelperson | Wichteln' } },
    { path: '/admin', component: Admin, meta: { requiresAuth: true, requiresAdmin: true, title: 'Admin | Wichteln' } },
    { path: '/', redirect: '/login', title: 'Login | Wichteln'  },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'Wichteln';

    const loggedIn = store.state.loggedIn;
    const isAdmin = store.state.userRole === 'admin';

    if (to.meta.requiresAuth && !loggedIn) {
        next('/login');
    } else if (to.meta.requiresAdmin && !isAdmin) {
        next('/settings');  // Redirect non-admins to another route
    } else {
        next();
    }
});

export default router;
