<template>
  <div class="container mx-auto py-8">
    <UserSettingsForm />
  </div>
</template>

<script>
  import UserSettingsForm from '../components/UserSettingsForm.vue';

  export default {
    name: 'UserSettingsView',
    components: { UserSettingsForm },
  };
</script>
