<template>
  <div class="container mx-auto py-8">
    <AssignmentDisplay />
  </div>
</template>

<script>
  import AssignmentDisplay from '../components/AssignmentDisplay.vue';

  export default {
    name: 'AssignmentView',
    components: { AssignmentDisplay },
  };
</script>
