<!-- src/components/UserSettingsForm.vue -->
<template>
  <div class="max-w-lg mx-auto my-10 bg-white p-8 rounded-lg shadow-lg">
    <h2 class="text-2xl font-semibold text-gray-700 mb-6">Einstellungen</h2>
    <form @submit.prevent="updateSettings">
      <div class="mb-4">
        <label class="block text-gray-600 text-sm font-medium mb-2" for="firstName">Vorname</label>
        <input
          v-model="settings.firstName"
          type="text"
          id="firstName"
          placeholder="Vorname"
          class="w-full p-3 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-600 text-sm font-medium mb-2" for="lastName">Nachname</label>
        <input
          v-model="settings.lastName"
          type="text"
          id="lastName"
          placeholder="Nachname"
          class="w-full p-3 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-600 text-sm font-medium mb-2" for="address">Adresse</label>
        <input
          v-model="settings.address"
          type="text"
          id="address"
          placeholder="Adresse"
          class="w-full p-3 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-600 text-sm font-medium mb-2" for="about">Über dich</label>
        <textarea
          v-model="settings.about"
          id="about"
          placeholder="Gebe hier hilfreiche Informationen für dein Wichtelgeschenk ein."
          rows="4"
          class="w-full p-3 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
        ></textarea>
      </div>
      <button
        type="submit"
        class="w-full bg-blue-500 text-white py-3 rounded-lg font-medium hover:bg-blue-600 transition duration-200"
      >
        Save Settings
      </button>
      <p v-if="errorMessage" class="mt-4 text-red-500">{{ errorMessage }}</p>
      <p v-if="successMessage" class="mt-4 text-green-500">{{ successMessage }}</p>
    </form>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import api from "@/services/api";

export default {
  setup() {
    const settings = ref({
      firstName: '',
      lastName: '',
      address: '',
      about: '',
    });
    const errorMessage = ref('');
    const successMessage = ref('');

    const fetchSettings = async () => {
      try {
        const response = await api.get('/api/user/settings', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        settings.value = response.data;
      } catch (error) {
        errorMessage.value = 'Failed to load settings';
      }
    };

    const updateSettings = async () => {
      try {
        await api.put('/api/user/settings', settings.value, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        successMessage.value = 'Settings updated successfully';
      } catch (error) {
        errorMessage.value = 'Failed to update settings';
      }
    };

    onMounted(fetchSettings);

    return { settings, errorMessage, successMessage, updateSettings };
  },
};
</script>

<style scoped>
/* Additional custom styles (if needed) */
</style>
