<template>
  <div class="flex items-center justify-center h-screen bg-gray-100">
    <LoginForm />
  </div>
</template>

<script>
  import LoginForm from '../components/LoginForm.vue';

  export default {
    name: 'LoginView',
    components: { LoginForm },
  };
</script>
