<!-- src/components/AssignmentDisplay.vue -->
<template>
  <div class="max-w-md lg:max-w-xl mx-auto my-10 bg-white p-8 rounded-lg shadow-lg">
    <h2 class="text-2xl font-semibold text-gray-800 mb-4 text-center">Deine Wichtelperson</h2>

    <div v-if="assignedUser" class="bg-gray-100 p-6 rounded-lg shadow-inner">
      <div class="flex items-center justify-center mb-4">
        <div class="bg-blue-500 text-white p-3 rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A7.975 7.975 0 0112 20a7.975 7.975 0 016.879-2.196M5 11a7 7 0 1114 0M12 7a1 1 0 100-2 1 1 0 000 2z" />
          </svg>
        </div>
      </div>

      <div class="text-center mb-4">
        <p class="text-lg font-semibold text-gray-700">{{ assignedUser.firstName || '(Kein Vorname)' }} {{ assignedUser.lastName || '(Kein Nachname)' }}</p>
        <p class="text-md font-semibold text-gray-700">{{ assignedUser.username || '(Kein Nutzername)'}}</p>
      </div>

      <div class="text-left">
        <p class="mb-2">
          <span class="font-semibold text-gray-600">Adresse:</span>
          <span v-if="assignedUser.address" class="text-gray-800">{{ ' ' + assignedUser.address }}</span>
          <span v-else class="text-gray-500 italic"> Keine Adresse angegeben.</span>
        </p>
        <p class="mb-2">
          <span class="font-semibold text-gray-600">Informationen:</span>
          <span v-if="assignedUser.about" class="text-gray-800"> {{ ' ' + assignedUser.about }}</span>
          <span v-else class="text-gray-500 italic"> Keine weiteren Informationen.</span>
        </p>
      </div>
    </div>

    <div v-else class="text-center text-gray-500 mt-4">
      <p>Dir wurde noch keine Wichtelperson zugeordnet.</p>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted } from 'vue';
  import api from "@/services/api";

  export default {
    setup() {
      const assignedUser = ref(null);

      const fetchAssignedUser = async () => {
        try {
          const response = await api.get('/api/user/assigned', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          });
          assignedUser.value = response.data;
        } catch (error) {
          console.error('Failed to load assigned user:', error.response?.data?.error || error.message);
        }
      };

      onMounted(fetchAssignedUser);

      return { assignedUser };
    },
  };
</script>

<style scoped>
  /* Additional custom styling if needed */
</style>
